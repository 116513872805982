import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'

const Policy = styled.div`
  margin: 0 auto;
  padding: ${theme.space[6]}px 0;
  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
  display: flex;
  flex-direction: column;
  color: ${theme.colors.blackhighlight};
`
Policy.title = styled.span`
  font-size: ${theme.font['3xl']};
  padding: ${theme.space[4]}px 0;
`

Policy.paragraph = styled.span`
  font-size: ${theme.font['md']};
  padding: ${theme.space[5]}px 0;
`

export default Policy
