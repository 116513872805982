import React from 'react'
import Policy from './dnrn-policy.styles'

const PolicyDetails = () => {
  return (
    <Policy>
      <Policy.title>
        Sitelio Domain Name Renewal Notification Policy
      </Policy.title>
      <Policy.paragraph>
        For domain registration services, email reminders are sent approximately
        one month prior to the domain expiration date and again approximately
        one week prior to the domain expiration date. If the domain is not
        renewed by the expiration date, an additional email notification will be
        sent within five days of the domain expiration date. All email reminders
        are sent to the registrant email address listed for the domain name.
      </Policy.paragraph>
    </Policy>
  )
}

export default PolicyDetails
