import React from 'react'
import Layout from '../../components/layout'
import PolicyDetails from '../../components/terms/dnrn-policy'
import TermsGrid from '../../components/terms/terms-grid'
import SEO from '../../components/seo'

const DNRNPolicy = () => {
  return (
    <Layout isTallFooter>
      <SEO
        title='Domain Name Renewal Notification Policy'
        pageType='home'
        flowType='none'
      />
      {typeof window !== 'undefined' && (
        <TermsGrid pathname={window.location.pathname} />
      )}
      <PolicyDetails />
    </Layout>
  )
}

export default DNRNPolicy
